<template>
  <div>
    <div class="bg-white shadow-sm rounded-lg p-4">
      <el-form :model="form" ref="form" :hide-required-asterisk="true">
        <el-form-item
          :label="$t('user.name')"
          prop="name"
          :rules="[{ required: true, message: 'The field is required'}]"
        >
          <el-input type="text" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.email')"
          prop="email"
          :rules="[{ required: true, message: 'The field is required'},{ type: 'email', message: 'The format email invalid'}]"
        >
          <el-input type="text" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.phone')"
          prop="phone"
          :rules="[{ required: true, message: 'The field is required'}]"
        >
          <el-input type="text" v-model="form.phone"></el-input>
        </el-form-item>
        <el-button type="primary" @click="updateForm('form')">Save register</el-button>
      </el-form>
    </div>
    <div class="bg-white shadow-sm rounded-lg p-4 mt-5">
      <el-form :model="formPassword" ref="formPassword" :hide-required-asterisk="true"
              :rules="rules"
              @submit.native.prevent>
        <el-form-item
          :label="$t('Current Password')"
          prop="current"
          :rules="[{ required: true, message: 'The field is required'}]"
        >
          <el-input type="password" v-model="formPassword.current" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('New Password')"
          prop="password"
          :rules="[{ required: true, message: 'The field is required'}]"
        >
          <el-input type="password" v-model="formPassword.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('Confirm Password')"
          prop="checkPass"
        >
          <el-input type="password" v-model="formPassword.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-button type="primary" @click="changePasword('formPassword')">Change Password</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import user from "@/services/api/user";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === null) {
        callback(new Error("Please input the password again"));
      } else if (value !== this.formPassword.password) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      form: {
        id: null,
        name: null,
        old_email: null,
        email: null,
        phone: null,
        status: 1
      },
      formPassword: {
        id: null,
        current: null,
        password: null,
        checkPass: null
      },
      rules: {
        checkPass: [{ validator: validatePass }]
      }
    };
  },
  mounted() {
    user
      .getById(this.$store.getters.id)
      .then(response => {
        this.form.id = response.id;
        this.formPassword.id = response.id;
        this.form.name = response.name;
        this.form.old_email = response.email;
        this.form.email = response.email;
        this.form.phone = response.phone;
      })
      .finally(() => {
        this.loading = true;
      });
  },
  methods: {
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          user
            .update(this.form)
            .then(() => {
              this.$message({
                message: "User success update information",
                type: "success",
                customClass: "message-success"
              });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    changePasword(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          user
            .changePassword(this.formPassword)
            .then(() => {
              this.$message({
                message: "Password changed successfully",
                type: "success",
                customClass: "message-success"
              });
            })
            .catch(() => {
              this.$message({
                message: 'Oops... Something wrong',
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style>
</style>j